import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import "../styles/styles.scss"

import SEO from "../components/seo"
import { Container, Table } from "react-bootstrap"

const IndexPage = ({ data }) => (
  <Container fluid id="page">
    <SEO title="Retro Roaming" description="" keywords="" />
    <h1>RETRO ROAMING in Southwold</h1>
    <Img fluid={data.retroRoamingBeach.childImageSharp.fluid} />

    <p>
      Our Retro Roamers are on the Southwold Harbour Camp Site. The magnificent
      sandy beach runs from your doorstep, all the way into the charming seaside
      town and beyond.
    </p>
    <p>
      Southwold is set in breathtaking surroundings, with stunning coastal walks
      and fabulous food. And the best way to enjoy these wonders is to live
      outside in a Roamer.
    </p>
    <p>What do you need to bring? Quite simply, nothing!</p>
    <p>Retro Roaming will spoil you.</p>

    <h1>The Goldie</h1>
    <Img fluid={data.hymer1.childImageSharp.fluid} />
    <Img fluid={data.hymer2.childImageSharp.fluid} />
    <p>
      <strong>The Goldie</strong> glows with retro luxury. This glamorous
      motorhome is in wonderful condition, time travelling straight from the
      late ’80s to wow you.
    </p>
    <Img fluid={data.hymer3.childImageSharp.fluid} />
    <p>
      The décor is soft with warm tones of brown and cream, a touch of Boho chic
      mixed with ’80s vintage sophistication. At the front is the lounge with
      two couches you can chill out on, two leather captain armchairs to relax
      in and a dining table.
    </p>
    <Img fluid={data.hymer4.childImageSharp.fluid} />
    <p>In the middle is the Bijou kitchen and a little mirrored bathroom.</p>

    <Img fluid={data.hymer8.childImageSharp.fluid} />
    <Img fluid={data.hymer5.childImageSharp.fluid} />
    <p>
      There are lots of cupboards and even a wardrobe, so you can enjoy dressing
      up and looking fabulous.
    </p>
    <Img fluid={data.hymer6.childImageSharp.fluid} />
    <p>
      The boudoir bedroom is at the back, surrounded by windows with macrame
      curtains to let the sun rays shine in.
    </p>
    <Img fluid={data.hymer7.childImageSharp.fluid} />
    <p>The Lounge is wrapped in panoramic views of wild nature and trees.</p>
    <Img fluid={data.hymer9.childImageSharp.fluid} />

    <h1>The Bonnie</h1>
    <Img fluid={data.bonnie1.childImageSharp.fluid} />
    <Img fluid={data.bonnie2.childImageSharp.fluid} />
    <p>
      <strong>The Bonnie</strong> has a quirky charm. Flying in from the early
      ’80s or a space film, this camping creation is pure open air luxury, and
      is most definitely one of a kind!
    </p>

    <Img fluid={data.bonnie3.childImageSharp.fluid} />
    <p>
      It has a double bed at one end and another double sized lounging bed at
      the other end. In the middle is the Bijou kitchen. There is also a
      spacious dining table and seating for four.
    </p>
    <Img fluid={data.bonnie4.childImageSharp.fluid} />
    <p>
      The Bonnie’s unique and stunning secret is it’s panoramic 360 degree
      windows. So a view wherever you turn of the Southwold famous sky line,
      stars, moon and more! All the windows have curtains too. They also have
      built in mosquito nets, should you desire a true outdoor experience on a
      hot night.
    </p>
    <Img fluid={data.bonnie5.childImageSharp.fluid} />
    <p class="disclaimer">* Bonnie photos are not taken at the actual pitch</p>

    <h2>Our Retro Roamers both have...</h2>
    <ul>
      <li>
        A super comfortable natural double mattress with cotton bedding. All the
        pillows and duvets are pure wool, made from sheep that run free on the
        Yorkshire hills.
      </li>
      <li>Cotton bath and beach towels.</li>
      <li>
        A kitchen with a sink, 4 hob cooker top and a fridge with a freezer
        compartment, for ice to chill those sunset cocktails.
      </li>
      <li>
        Stylish retro kitchenware, including a kettle, cafetiere and everything
        you need from breakfast to dinner.
      </li>
      <li>
        A supply of fresh coffee, tea, milk, sugar, butter, jam, salt, pepper
        and other condiments.
      </li>
      <li>Sun loungers, outdoor table and chairs.</li>
      <li>Outside fairy lights to add a soft ambient night time twinkle. </li>
      <li>
        Fire pit and wood for a barbecue, or a cosy evening fire. Snuggle up in
        the shaggy rugs as you lounge around the fire stargazing.
      </li>
      <li>Indoor and outdoor games galore.</li>
      <li>Music bluetooth portable speaker.</li>
      <li>
        Solar power, which provides USB charging points and internal lighting.
      </li>
      <li>Water tank.</li>
      <li>
        The campsite has shower/toilet blocks. The Goldie bathroom is just for
        those nighttime needs.
      </li>
    </ul>

    <h1>The Pitches</h1>
    <Img fluid={data.pitch1.childImageSharp.fluid} />
    <p>
      <strong>The Pitches</strong> are perfectly situated, with a stunning sandy
      beach just a two minute walk over the sand dunes. Wander to the right and
      the Harbour begins, or turn to the left and it’s a ten minute stroll into
      Southwold town.
    </p>
    <Img fluid={data.pitch2.childImageSharp.fluid} />
    <p>
      The Goldie and The Bonnie are on corner pitches which run along the edge
      of the camping field. These give you uninterrupted views of the fields and
      the Southwold horizon including the iconic lighthouse, church and old
      water tower.
    </p>

    <Img fluid={data.extra3.childImageSharp.fluid} />
    <p>
      The never-ending skyline is like a changing painting from sunrise to
      sunset, and at night the sky is startastic. The campsite is in a truly
      unspoilt location, with an abundance of birdlife to watch.
    </p>
    <Img fluid={data.pitch3.childImageSharp.fluid} />

    <h1>Southwold</h1>
    <Img fluid={data.southwold1.childImageSharp.fluid} />
    <p>
      <strong>Southwold’s</strong> sandy beach is a dream, for a splash or a
      swim, some bat and ball, for soaking up the sunshine or simply to watch
      the world go by. Walk along the beach to the quirky pier of amusements.
      And then beyond, to the stunning open wild beaches of South Cove. The
      vibrant traditional high street has lots of little shops to explore. There
      are also vintage markets and emporiums nearby.
    </p>

    <Img fluid={data.southwold2.childImageSharp.fluid} />
    <p>
      Food and drink – there is a vast selection of fresh Southwold produce in
      characterful venues, all with their own unique charm.
    </p>
    <p>
      It offers candlelit restaurants, sea front beach hut cafes, pretty tea
      rooms with cottage gardens, scrumdiddily homemade take away food and
      luxury cuisine dining in The Swan and The Crown hotels. To add to this are
      the ancient cosy pubs, their walls adorned with vintage pics of
      Southwold’s days of old. There are food shops galore and fabulous fish and
      chips for a-munching on the harbour, pier or beach.
    </p>
    <p>
      For the ultimate outdoor eating, return to your Roamer with some freshly
      caught fish to barbecue on your fairy lit pitch, accompanied by some
      music. To finish the evening amble down to the beach to build a fire and
      listen to the waves.
    </p>

    <Img fluid={data.southwold3.childImageSharp.fluid} />
    <p>
      The Harbour is a delight with an enchanting traditional atmosphere. Wander
      down, looking at the fishing boats and huts selling their day's catch.
      Here is Mrs T’s Fish and Chips (one of the best locally). Next door is a
      superb restaurant The Sole Bay Fish Company. Specialising in seafood
      dishes all caught earlier that day from their own family boat. The Harbour
      Inn overlooks a ray of colourful boats, rocking gently on the twinkling
      water. This is a great place to watch the sun go down.
    </p>

    <Img fluid={data.extra1.childImageSharp.fluid} />
    <p>
      There are stunning coastal walks. Or take a stroll over the bridge into
      the quaint little village of Walberswick, it really is a picture postcard
      village. Find the delightful tea room gardens. Then catch the rowing boat
      ferry back. A cute end to an idyllic day.
    </p>
    <p>
      Cycle or walk through the awesome Dunwich Heath and Forest to The Ship
      pub. After a well deserved meal, wobble and giggle back along the coast
      through the grassy sea marshes on the boardwalks.
    </p>

    <Img fluid={data.southwold4.childImageSharp.fluid} />
    <p>
      For the even more energetic, you can find surf lessons and horse riding on
      the nearby beaches. Or for a peaceful morning, take a yoga class by the
      waves.
    </p>
    <Img fluid={data.extra4.childImageSharp.fluid} />
    <p>
      The very scenic nine hole heathland golf course is a challenge for all
      golfing abilities. Or there is a lovely golf putting green by the seafront
      with tea rooms.
    </p>
    <p>
      The area is famous for Adnams beer and it is made in Southwold itself. You
      can have a tour of the Brewery and make your own Adnams gin. Or ride
      around the town in a carriage pulled by the Adnams shire horses.
    </p>
    <p>
      A fantastical evening out is the wonderfully eccentric Electric Palace
      Cinema.
    </p>
    <p>
      Or perhaps you’d like to hire a boat at Oulton Broad and motor merrily
      merrily down the rivers with a picnic, stopping off for ice cream.
    </p>
    <Img fluid={data.extra2.childImageSharp.fluid} />
    <p>
      This is just a small selection of the fun on offer. If you are Retro
      Roaming for a much needed rest and don’t fancy exerting yourself, then we
      have a pitch full of fun. From Scrabble to Connect 4, wooden puzzles to
      tease your brain, Agatha Christie’s Who Done It, Monopoly, the
      never-ending challenging Rubik’s Cube, Boules, Pro Swingball, the list
      goes on and on. What with the views and the ambience of the Roamers, you
      could easily end up never ever leaving the pitch!
    </p>

    <h1>Prices & Booking</h1>
    <p>
      Please email me,{" "}
      <a href="mailto:jo.dollydare@yahoo.co.uk">jo.dollydare@yahoo.co.uk</a> to
      request a booking and make sure to include which dates you are interested
      in.
    </p>
    <p>Prices are £122 a night for 2 adults with a 4 night minimum.</p>
    <iframe
      id="frame"
      title="booking-document"
      src="https://docs.google.com/spreadsheets/d/e/2PACX-1vTszMyrgrVosOotdBvoZCm-GsUI4eTa2fN9ITfMne63AXxcXdFvP_aU_sbpzwGwL7tD8_N9vaaEElUI/pubhtml?gid=0&amp;single=true&amp;widget=true&amp;headers=false"
    ></iframe>
  </Container>
)

export default IndexPage

export const query = graphql`
  query {
    retroRoamingBeach: file(relativePath: { eq: "retroRoamingBeach.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    hymer1: file(relativePath: { eq: "hymer1.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    hymer2: file(relativePath: { eq: "hymer2.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    hymer3: file(relativePath: { eq: "hymer3.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    hymer4: file(relativePath: { eq: "hymer4.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    hymer5: file(relativePath: { eq: "hymer5.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    hymer6: file(relativePath: { eq: "hymer6.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    hymer7: file(relativePath: { eq: "hymer7.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    hymer8: file(relativePath: { eq: "hymer8.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    hymer9: file(relativePath: { eq: "hymer9.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    bonnie1: file(relativePath: { eq: "bonnie1.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    bonnie2: file(relativePath: { eq: "bonnie2.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    bonnie3: file(relativePath: { eq: "bonnie3.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    bonnie4: file(relativePath: { eq: "bonnie4.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bonnie5: file(relativePath: { eq: "bonnie5.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    pitch1: file(relativePath: { eq: "pitch1.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    pitch2: file(relativePath: { eq: "pitch2.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    pitch3: file(relativePath: { eq: "pitch3.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    extra1: file(relativePath: { eq: "extra1.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    extra2: file(relativePath: { eq: "extra2.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    extra3: file(relativePath: { eq: "extra3.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    extra4: file(relativePath: { eq: "extra4.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    southwold1: file(relativePath: { eq: "southwold1.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    southwold2: file(relativePath: { eq: "southwold2.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    southwold3: file(relativePath: { eq: "southwold3.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    southwold4: file(relativePath: { eq: "southwold4.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
